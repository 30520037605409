:root {
  --primary-primary-dark: #e26c2d;
  --primary-primary: #f26b26;
  --primary-primary-light: #f8f2ef;
  --error-red: #f8d7da;
  --reds-errors-red-100: #f8d7da;
  --reds-errors-red-400: #e35d6a;
  --reds-errors-red-500: #dc3545;
  --reds-errors-red-600: #b02a37;
  --yellows-warning-yellow-100: #fff3cd;
  --yellows-warning-yellow-600: #cc9a06;
  --blue-links-blue-100: #cfe2ff;
  --blue-links-blue-400: #3d8bfd;
  --blue-links-blue-600: #0a58ca;
  --greens-success-green-100: #d1e7dd;
  --greens-success-green-400: #479f76;
  --greens-success-green-500: #198754;
  --greens-success-green-600: #146c43;
  --greens-success-green-700: #0f5132;
  --default-white: #fff;
  --default-black: #000;
  --secondary-border-1: #e2e2e2;
  --secondary-border-2: #e6e6e6;
  --secondary-secondary: #666;
  --secondary-secondary-dark: #333;
  --secondary-secondary-light: #abb5be;
  --grays-gray-100: #f8f9fa;
  --grays-gray-200: #e9ecef;
  --grays-gray-300: #dee2e6;
  --grays-gray-400: #ced4da;
  --grays-gray-600: #6c757d;
  --general-danger: #dc3545;
  --general-success: #28a745;
  --tofisav2-red-100: #ff1607;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  position: relative;
  background-color: #fff;
  z-index: 1;
}

body.modal-open {
  padding-right: 0 !important;
}

/* Style the scrollbar track */
body *::-webkit-scrollbar {
  width: 7px; /* width of the scrollbar */
  height: 7px;
}

/* Style the scrollbar thumb */
body *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 4px; /* roundness of the thumb */
}

/* Style the scrollbar track when mouse is over it */
body *::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0;
}

/* Style the scrollbar thumb when mouse is over it */
body *::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Style the scrollbar corner */
body *::-webkit-scrollbar-corner {
  background-color: #f0f0f0; /* color of the corner */
}

body *::-webkit-scrollbar {
  scrollbar-width: 0.1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

input:focus {
  outline: none;
}

input::-webkit-inner-spin-button {
  appearance: none;
}

::placeholder {
  font-weight: 400;
  color: #777777;
  font-size: 14px;
  line-height: 24px;
}

/* SWIPER CUSTOMIZATION: START */
.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}

.swiper-pagination-bullet {
  background: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  width: 2rem !important;
  border-radius: 10px !important;
}
/* SWIPER CUSTOMIZATION: END */

.codeTop {
  margin-top: 26px !important;
}

.form-control {
  width: 100% !important;
}

.form-control-active {
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 2;
  color: #111;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 49px !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: unset !important;
}
.carousel .control-next.control-arrow:before {
  border-left: unset !important;
}

.carousel .control-prev.control-arrow:before,
.carousel.carousel-slider .control-prev.control-arrow:before {
  background-image: url('../../public/Assets/Images/productDetail/previous.svg');
  background-repeat: no-repeat;
  width: 2rem;
  height: 2.5rem;
  border-top: unset !important;
  border-bottom: unset !important;
}
.carousel .control-next.control-arrow:before,
.carousel.carousel-slider .control-next.control-arrow:before {
  background-image: url('../../public/Assets/Images/productDetail/next.svg');
  background-repeat: no-repeat;
  width: 2rem;
  height: 2.5rem;
  border-top: unset !important;
  border-bottom: unset !important;
}

/* *****************************HOME STORY***************************** */
.homeStoryBase {
  padding: 10px 15px;
  overflow-x: scroll;
}

.homeStoryBase::-webkit-scrollbar {
  width: 0;
  display: none;
}

.perStorySpace {
  margin-right: 21px;
}

.storyBase {
  border: 2px solid #f26b26;
  border-radius: 100%;
  width: 70px;
  height: 70px;
}

.webStorySize {
  border: 2px solid #f26b26;
  border-radius: 100%;
  width: 100px;
  height: 100px;
}

/* ******************HOME BANNER********************* */
.homeBannerBase {
  margin: 21px 15px 13px 15px;
}

.bannerImageBase {
  height: 100%;
}

.perImageBase {
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.bannerTextClass {
  bottom: 12px;
  left: 12px;
}
