.notfound_container {
  display: flex;
  height: 100dvh;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.notfound_container_header {
  width: 40%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  align-items: center;
}

.notfound_container_content {
  background-image: url('/Assets/Icons/Frame.svg');
  background-position: center top;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 456px;
  height: 442px;
  > p {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
.notfound_container_content_search {
  margin-top: 10px;
  width: 100%;
  > div {
    width: 100%;
  }
}

.notfound_container_content_btn {
  width: 50%;
  margin: 20px auto 0 auto;
}

.not_found_categories {
  margin-top: 20px;
  width: 32%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.not_found_categories_link {
  width: 100%;

  > div {
    width: 100%;
    > span {
      font-size: 16px;
      color: #333333;
    }
  }
}

.not_found_categories_placeholder {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.notfound_container_footer {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .notfound_container {
    height: auto;
    padding: 20px 0;
  }

  .notfound_container_header {
    width: 90%;
  }
  .notfound_container_content {
    width: 100%;
    background-size: contain;
    height: 426px;
  }
  .notfound_container_content_btn {
    width: 80%;
  }

  .not_found_categories_link {
    > div {
      > span {
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .not_found_categories {
    width: 90%;
    grid-template-columns: repeat(2, 1fr);
  }
  .not_found_categories_placeholder {
    grid-template-columns: repeat(2, 1fr);
  }
}
