$fontFamily: 'Rubik';
$borderRadius: 4px;

.wrapper {
  height: 100dvh;
  background-color: var(--primary-primary);

  p {
    margin: 0;
  }

  .cloud {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px;
  }

  .message {
    text-align: center;
    margin-top: 15px;

    * {
      font-family: $fontFamily;
      font-weight: bold;
    }
  }
  .error_code {
    position: relative;
    margin: 0 auto;
    padding: 30px 0;
    width: 70%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    > span {
      border-radius: 10px;
      color: #fff;
      font-size: 36px;
    }

    .redirect_button {
      width: 236px;

      button {
        width: 100%;
        color: var(--default-white);
        font-family: $fontFamily;
        font-size: 16px;
        height: 44px;
        font-style: normal;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-block: 8px;
        padding-inline: 12px;
        outline: none;
        border: none;
        background: var(--default-white);
        * {
          color: var(--secondary-secondary);
        }
      }

      span {
        color: var(--primary-primary) !important;
      }
    }

    .cloud_big_left {
      position: absolute;
      left: 125px;
    }
    .cloud_tiny_left {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .cloud_big_right {
      position: absolute;
      right: 125px;
      top: 112px;
    }
    .cloud_tiny_right {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
  }

  .section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .big_cloud {
      position: relative;

      svg {
        width: 90%;
      }
      .wires {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 4%;
        left: 1%;

        svg {
          width: 90%;
        }
      }

      .left_single_wire {
        position: absolute;
        left: -73.1%;
        top: 47%;
      }

      .right_single_wire {
        position: absolute;
        right: -64.3%;
        top: 59%;
      }
    }

    .rock_left {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .rock_right {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}
